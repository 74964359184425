'use strict';

/* eslint no-undef: "off" */
/**
 * deleteBindConfirmSession api
 */
define('deleteBindConfirmSessionApi',['baseApiPost'], function (baseApiPost) {
    return function () {
        var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        return baseApiPost('/rest/V1/xmapi/deleteBindConfirmSession', params);
    };
});
