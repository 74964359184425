'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

define('GAReport',['appStorage', 'util'], function (appStorage, Util) {
    //设备类型
    var VERSION = function () {
        var reg = /iPhone|iPod|BlackBerry|BB10|Pre|Palm|Googlebot-Mobile|mobi|Safari Mobile|Windows Mobile|Android|Opera Mini|mobile/i;

        return reg.test(window.navigator.userAgent) ? "MOBILE" : "PC";
    }();
    var CURRENCYCODE = window.commonDataJSON.current_currency_code || ""; // 货币code
    // 当前语言
    var LANGUAGE = function () {
        var stores = window.commonDataJSON.stores;
        for (var item in stores) {
            if (stores[item]['is_active'] && stores[item]['choosed']) {
                return stores[item]['name'];
            }
        }
        return "";
    }();

    var GAReport = function () {
        function GAReport() {
            _classCallCheck(this, GAReport);
        }

        // 获取页面title


        _createClass(GAReport, [{
            key: 'getPageTitle',
            value: function getPageTitle() {
                return document.title;
            }

            // 获取当前页面code

        }, {
            key: 'getPageGroup',
            value: function getPageGroup() {
                if (window.gaCommonData && _typeof(window.gaCommonData) === "object") {
                    return window.gaCommonData.pageGroup || "";
                }
                return "";
            }
            // 替换变量

        }, {
            key: 'writeVariable',
            value: function writeVariable(str) {
                str = (str || "").replace("#PAGEGROUP#", this.getPageGroup());
                str = str.replace("#VERSION#", VERSION);

                return str;
            }
        }, {
            key: 'init',
            value: function init(options) {
                if (options.pageGroup) {
                    window.gaCommonData = window.gaCommonData || {};
                    window.gaCommonData.pageGroup = options.pageGroup;
                }
                this.pageLoad();
            }

            //页面加载

        }, {
            key: 'pageLoad',
            value: function pageLoad() {
                if (!this.getPageGroup()) {
                    return;
                }
                this.report({
                    'event': 'webpageGroup',
                    'eventAction': this.writeVariable('WEB_PAGEGROUP_#PAGEGROUP#'),
                    'eventCategory': this.writeVariable('WEB_PAGEGROUP_#VERSION#'),
                    'eventLabel': this.getPageTitle(),
                    'eventValue': LANGUAGE,
                    'ab_version': Util.getCookie('ab_version') === "" ? 'default' : Util.getCookie('ab_version')
                });

                if (window.ath_send) {
                    window.ath_send('webpageGroup', {
                        'eventAction': this.writeVariable('WEB_PAGEGROUP_#PAGEGROUP#'),
                        'eventCategory': this.writeVariable('WEB_PAGEGROUP_#VERSION#'),
                        'eventLabel': this.getPageTitle(),
                        'eventValue': LANGUAGE,
                        'ab_version': Util.getCookie('ab_version') === "" ? 'default' : Util.getCookie('ab_version')
                    });
                }
            }

            // 用户的点击的点击

        }, {
            key: 'customerClick',
            value: function customerClick(data) {
                this.report({
                    'event': 'customerClick',
                    'eventAction': this.writeVariable(data.eventAction),
                    'eventCategory': this.writeVariable(data.eventCategory),
                    'eventLabel': this.getPageTitle(),
                    'eventValue': LANGUAGE
                });

                if (window.ath_send) {
                    window.ath_send('customerClick', {
                        'eventAction': this.writeVariable(data.eventAction),
                        'eventCategory': this.writeVariable(data.eventCategory),
                        'eventLabel': this.getPageTitle(),
                        'eventValue': LANGUAGE
                    });
                }
            }

            // 搜索

        }, {
            key: 'searchData',
            value: function searchData(data) {
                this.report({
                    'event': 'search',
                    'searchTerm': data.searchTerm
                });

                if (window.ath_send) {
                    window.ath_send('search', {
                        'searchTerm': data.searchTerm
                    });
                }
            }

            // 产品曝光

        }, {
            key: 'productExposure',
            value: function productExposure(data) {
                if (!data.productList || data.productList.length <= 0) {
                    return;
                }

                // 清理之前的数据
                this.report({
                    'ecommerce': null
                });

                this.report({
                    'event': 'productExposure',
                    'eventAction': this.writeVariable(data.eventAction),
                    'eventCategory': this.writeVariable(data.eventCategory),
                    'eventLabel': this.getPageTitle(),
                    'eventValue': LANGUAGE,
                    'ecommerce': {
                        'currencyCode': CURRENCYCODE, // Local currency is optional.
                        'impressions': data.productList
                    }
                });

                if (window.ath_send) {
                    window.ath_send('productExposure', {
                        'eventAction': this.writeVariable(data.eventAction),
                        'eventCategory': this.writeVariable(data.eventCategory),
                        'eventLabel': this.getPageTitle(),
                        'eventValue': LANGUAGE,
                        'ecommerce': {
                            'currencyCode': CURRENCYCODE, // Local currency is optional.
                            'impressions': data.productList
                        }
                    });
                }
            }

            // 产品点击

        }, {
            key: 'productClick',
            value: function productClick(data) {
                if (!data.productList || data.productList.length <= 0) {
                    return;
                }

                // 清理之前的数据
                this.report({
                    'ecommerce': null
                });

                this.report({
                    'event': 'productClick',
                    'eventAction': this.writeVariable(data.eventAction),
                    'eventCategory': this.writeVariable(data.eventCategory),
                    'eventLabel': this.getPageTitle(),
                    'eventValue': LANGUAGE,
                    'ecommerce': {
                        'click': {
                            'actionField': {
                                'list': data.location
                            },
                            'products': data.productList
                        }
                    }
                });

                if (window.ath_send) {
                    window.ath_send('productClick', {
                        'eventAction': this.writeVariable(data.eventAction),
                        'eventCategory': this.writeVariable(data.eventCategory),
                        'eventLabel': this.getPageTitle(),
                        'eventValue': LANGUAGE,
                        'ecommerce': {
                            'click': {
                                'actionField': {
                                    'list': data.location
                                },
                                'products': data.productList
                            }
                        }
                    });
                }
            }

            // 产品详情展示

        }, {
            key: 'productView',
            value: function productView(data) {
                if (!data.productList || data.productList.length <= 0) {
                    return;
                }

                // 清理之前的数据
                this.report({
                    'ecommerce': null
                });

                this.report({
                    'event': 'productView',
                    'eventAction': this.writeVariable(data.eventAction),
                    'eventCategory': this.writeVariable(data.eventCategory),
                    'eventLabel': this.getPageTitle(),
                    'eventValue': LANGUAGE,
                    'ecommerce': {
                        'currencyCode': CURRENCYCODE,
                        'detail': {
                            'actionField': { 'list': data.configName }, // Local currency is optional.
                            'products': data.productList
                        }
                    }
                });

                if (window.ath_send) {
                    window.ath_send('productView', {
                        'eventAction': this.writeVariable(data.eventAction),
                        'eventCategory': this.writeVariable(data.eventCategory),
                        'eventLabel': this.getPageTitle(),
                        'eventValue': LANGUAGE,
                        'ecommerce': {
                            'currencyCode': CURRENCYCODE,
                            'detail': {
                                'actionField': { 'list': data.configName }, // Local currency is optional.
                                'products': data.productList
                            }
                        }
                    });
                }
            }

            // 产品加购物

        }, {
            key: 'productAddToCart',
            value: function productAddToCart(data) {
                if (!data.productList || data.productList.length <= 0) {
                    return;
                }

                // 清理之前的数据
                this.report({
                    'ecommerce': null
                });
                var promotionName = "";
                var promotionId = "";
                var promotionUrl = "";

                if (promotionObj = appStorage.get('lead_promotion')) {
                    console.log('promotionObj', promotionObj);
                    promotionName = promotionObj.name;
                    promotionId = promotionObj.id;
                    promotionUrl = promotionObj.url;
                }

                this.report({
                    'event': 'addToCart',
                    'eventAction': this.writeVariable(data.eventAction),
                    'eventCategory': this.writeVariable(data.eventCategory),
                    'eventLabel': this.getPageTitle(),
                    'eventValue': LANGUAGE,
                    'ecommerce': {
                        'currencyCode': CURRENCYCODE,
                        'add': {
                            'actionField': { 'list': data.configName }, // Local currency is optional.
                            'products': data.productList
                        }
                    },
                    'promotion_name': promotionName,
                    'promotion_id': promotionId,
                    'promotion_url': promotionUrl
                });

                if (window.ath_send) {
                    window.ath_send('addToCart', {
                        'eventAction': this.writeVariable(data.eventAction),
                        'eventCategory': this.writeVariable(data.eventCategory),
                        'eventLabel': this.getPageTitle(),
                        'eventValue': LANGUAGE,
                        'ecommerce': {
                            'currencyCode': CURRENCYCODE,
                            'add': {
                                'actionField': { 'list': data.configName }, // Local currency is optional.
                                'products': data.productList
                            }
                        },
                        'promotion_name': promotionName,
                        'promotion_id': promotionId,
                        'promotion_url': promotionUrl
                    });
                }
            }
        }, {
            key: 'productRemoveFormCart',
            value: function productRemoveFormCart(data) {
                if (!data.productList || data.productList.length <= 0) {
                    return;
                }

                // 清理之前的数据
                this.report({
                    'ecommerce': null
                });

                this.report({
                    'event': 'removeFromCart',
                    'eventAction': this.writeVariable(data.eventAction),
                    'eventCategory': this.writeVariable(data.eventCategory),
                    'eventLabel': this.getPageTitle(),
                    'eventValue': LANGUAGE,
                    'ecommerce': {
                        'remove': {
                            'products': data.productList
                        }
                    }
                });

                if (window.ath_send) {
                    window.ath_send('removeFromCart', {
                        'eventAction': this.writeVariable(data.eventAction),
                        'eventCategory': this.writeVariable(data.eventCategory),
                        'eventLabel': this.getPageTitle(),
                        'eventValue': LANGUAGE,
                        'ecommerce': {
                            'remove': {
                                'products': data.productList
                            }
                        }
                    });
                }
            }
            // 进入结算页面

        }, {
            key: 'viewCheckout',
            value: function viewCheckout(data) {
                // 清理之前的数据
                this.report({
                    'ecommerce': null
                });

                var reportData = {
                    'event': 'viewCheckout',
                    'eventAction': this.writeVariable(data.eventAction),
                    'eventCategory': this.writeVariable(data.eventCategory),
                    'eventLabel': this.getPageTitle(),
                    'eventValue': LANGUAGE,
                    'ecommerce': {
                        'checkout': {
                            'actionField': {
                                'step': data.step,
                                'option': data.option
                            }
                        }
                    }
                };

                var athReportData = {
                    'eventAction': this.writeVariable(data.eventAction),
                    'eventCategory': this.writeVariable(data.eventCategory),
                    'eventLabel': this.getPageTitle(),
                    'eventValue': LANGUAGE,
                    'ecommerce': {
                        'checkout': {
                            'actionField': {
                                'step': data.step,
                                'option': data.option
                            }
                        }
                    }
                };

                if (data.productList) {
                    reportData.ecommerce.checkout.products = data.productList;
                    athReportData.ecommerce.checkout.products = data.productList;
                }
                this.report(reportData);

                if (window.ath_send) {
                    window.ath_send('viewCheckout', athReportData);
                }
            }
            // 进入购物车页面

        }, {
            key: 'viewShoppingCart',
            value: function viewShoppingCart(data) {
                // 清理之前的数据
                this.report({
                    'ecommerce': null
                });

                var reportData = {
                    'event': 'viewShoppingCart',
                    'eventAction': this.writeVariable(data.eventAction),
                    'eventCategory': this.writeVariable(data.eventCategory),
                    'eventLabel': this.getPageTitle(),
                    'eventValue': LANGUAGE,
                    'ecommerce': {
                        'checkout': {
                            'actionField': {
                                'step': data.step,
                                'option': data.option
                            }
                        }
                    }
                };

                var athReportData = {
                    'eventAction': this.writeVariable(data.eventAction),
                    'eventCategory': this.writeVariable(data.eventCategory),
                    'eventLabel': this.getPageTitle(),
                    'eventValue': LANGUAGE,
                    'ecommerce': {
                        'checkout': {
                            'actionField': {
                                'step': data.step,
                                'option': data.option
                            }
                        }
                    }
                };

                if (data.productList) {
                    reportData.ecommerce.checkout.products = data.productList;
                    athReportData.ecommerce.checkout.products = data.productList;
                }

                this.report(reportData);

                if (window.ath_send) {
                    window.ath_send('viewShoppingCart', athReportData);
                }
            }

            // 下单

        }, {
            key: 'purchase',
            value: function purchase(data) {
                if (!data.productList || data.productList.length <= 0) {
                    return;
                }

                // 清理之前的数据
                this.report({
                    'ecommerce': null
                });
                var promotionName = "";
                var promotionId = "";
                var promotionUrl = "";
                if (promotionObj = appStorage.get('lead_promotion')) {
                    promotionName = promotionObj.name;
                    promotionId = promotionObj.id;
                    promotionUrl = promotionObj.url;
                }

                this.report({
                    'event': 'purchase',
                    'eventAction': this.writeVariable(data.eventAction),
                    'eventCategory': this.writeVariable(data.eventCategory),
                    'eventLabel': this.getPageTitle(),
                    'eventValue': LANGUAGE,
                    'ecommerce': {
                        'currencyCode': CURRENCYCODE,
                        'checkout_option': {
                            'actionField': {
                                'step': data.step,
                                'option': data.option
                            }
                        },
                        'purchase': {
                            'actionField': data.actionField,
                            'products': data.productList
                        }
                    },
                    'promotion_name': promotionName,
                    'promotion_id': promotionId,
                    'promotion_url': promotionUrl
                });

                if (window.ath_send) {
                    window.ath_send('purchase', {
                        'eventAction': this.writeVariable(data.eventAction),
                        'eventCategory': this.writeVariable(data.eventCategory),
                        'eventLabel': this.getPageTitle(),
                        'eventValue': LANGUAGE,
                        'ecommerce': {
                            'currencyCode': CURRENCYCODE,
                            'checkout_option': {
                                'actionField': {
                                    'step': data.step,
                                    'option': data.option
                                }
                            },
                            'purchase': {
                                'actionField': data.actionField,
                                'products': data.productList
                            }
                        },
                        'promotion_name': promotionName,
                        'promotion_id': promotionId,
                        'promotion_url': promotionUrl
                    });
                }
            }

            // 推广展示

        }, {
            key: 'promotionView',
            value: function promotionView(data) {
                if (!data.promotions || data.promotions.length <= 0) {
                    return;
                }

                // 清理之前的数据
                this.report({
                    'ecommerce': null
                });

                for (i in data.promotions) {
                    this.report({
                        'event': 'promotionView',
                        'eventAction': this.writeVariable(data.eventAction),
                        'eventCategory': this.writeVariable(data.eventCategory),
                        'eventLabel': this.getPageTitle(),
                        'eventValue': LANGUAGE,
                        'promotion_name': data.promotions[i].name,
                        'promotion_id': data.promotions[i].id,
                        'promotion_url': data.promotions[i].url
                    });

                    if (window.ath_send) {
                        window.ath_send('promotionView', {
                            'eventAction': this.writeVariable(data.eventAction),
                            'eventCategory': this.writeVariable(data.eventCategory),
                            'eventLabel': this.getPageTitle(),
                            'eventValue': LANGUAGE,
                            'promotion_name': data.promotions[i].name,
                            'promotion_id': data.promotions[i].id,
                            'promotion_url': data.promotions[i].url
                        });
                    }
                }
            }

            //推广点击

        }, {
            key: 'promotionClick',
            value: function promotionClick(data) {
                if (!data.promotions || data.promotions.length <= 0) {
                    return;
                }

                // 清理之前的数据
                this.report({
                    'ecommerce': null
                });

                for (i in data.promotions) {
                    this.report({
                        'event': 'promotionClick',
                        'eventAction': this.writeVariable(data.eventAction),
                        'eventCategory': this.writeVariable(data.eventCategory),
                        'eventLabel': this.getPageTitle(),
                        'eventValue': LANGUAGE,
                        'promotion_name': data.promotions[i].name,
                        'promotion_id': data.promotions[i].id,
                        'promotion_url': data.promotions[i].url
                    });

                    if (window.ath_send) {
                        window.ath_send('promotionClick', {
                            'eventAction': this.writeVariable(data.eventAction),
                            'eventCategory': this.writeVariable(data.eventCategory),
                            'eventLabel': this.getPageTitle(),
                            'eventValue': LANGUAGE,
                            'promotion_name': data.promotions[i].name,
                            'promotion_id': data.promotions[i].id,
                            'promotion_url': data.promotions[i].url
                        });
                    }

                    appStorage.set('lead_promotion', data.promotions[i]);
                }
            }
        }, {
            key: 'report',
            value: function report(data) {
                window.dataLayer = window.dataLayer || [];
                data.pageGroup = this.writeVariable("#PAGEGROUP");
                window.dataLayer.push(data);
            }
        }, {
            key: 'getProductVariant',
            value: function getProductVariant(item) {
                var variant = "";
                var color = "";
                var size = "";

                (item.opptions || []).map(function (option) {
                    if (option.label == "MEMORY") {
                        size = option.value;
                    } else if (option.label == "COLOR") {
                        color = option.value;
                    }
                });
                variant = color && size ? color + "\/" + size : color + size;

                return variant;
            }
        }, {
            key: 'productList',
            value: function productList(list, offset) {
                offset = offset || 0;
                var that = this;
                var gaProductList = (list || []).map(function (item, index) {
                    return that.productItem(item, offset + index + 1);
                });
                return gaProductList;
            }
        }, {
            key: 'productItem',
            value: function productItem(item, index, qty, assign) {

                var gaItem = Object.assign({
                    'name': item.name, // Name or ID is required.
                    'id': item.sku || "",
                    'price': item.price || item.final_price,
                    'brand': item.brand || "",
                    'category': item.category || "",
                    'item_category': item.category || "",
                    'item_category1': item.item_category1 || "",
                    'item_category2': item.item_category2 || "",
                    'item_category3': item.item_category3 || "",
                    'item_category4': item.product_class || "",
                    'item_category5': item.product_series || "",
                    'product_class': item.product_class || "",
                    'product_series': item.product_series || "",
                    'series': item.product_series || "",
                    'variant': this.getProductVariant(item),
                    'position': index,
                    'index': index,
                    'item_name': item.name,
                    'item_id': item.id,
                    'item_brand': item.brand,
                    'affiliation': '',
                    'coupon': '',
                    'discount': 0,
                    'currency': window.commonDataJSON.current_currency_code,
                    'quantity': (qty ? qty : item.qty) || 1
                }, assign || {});
                return gaItem;
            }

            // 雅典娜埋码使用方法

        }, {
            key: 'ath_send',
            value: function ath_send(eventname, param, beacon) {
                if (window.ath_send) {
                    window.ath_send(eventname, param, beacon);
                }
            }
        }]);

        return GAReport;
    }();

    return GAReport;
});
