'use strict';

/**
 * cms index module
 */
require(['vue', 'homeApp', 'vueInfiniteScroll', 'vueLazyload', 'validate', 'vueTopProgress', 'modal', 'polyfill', 'es6PromiseAuto'], function (Vue, homeApp, vueInfiniteScroll, vueLazyload, validate, vueTopprogress, VModal) {
  /* eslint no-new: "off" */
  Vue.config.productionTip = false;
  Vue.use(vueInfiniteScroll);
  Vue.use(vueLazyload, {
    preLoad: 1.3,
    // error: 'dist/error.png',
    loading: require.toUrl('images/lazyload/loading-bars.svg'),
    attempt: 2
  });
  Vue.use(validate, {
    locale: 'en',
    events: ''
  });
  // 使用 vue top progress
  Vue.use(vueTopprogress);
  // 使用modal
  Vue.use(VModal.default, { componentName: 'modal' });
  // 实例化vue
  new Vue({
    el: '#app',
    template: '<homeApp/>',
    components: {
      homeApp: homeApp
    }
  });
});
define("Magento_Cms/modules/cms_index", function(){});

