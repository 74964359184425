'use strict';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

/* eslint no-undef: "off" */
/**
 * main container
 */
define('cmsIndexMain',['homeBanner', 'util', 'homeAdsApi', 'adsProductSlider', 'adsBaseContent', 'timer', 'homeReviews'], function (homeBanner, Util, homeAdsApi, adsProductSlider, adsBaseContent, timer, homeReviews) {
  return {
    template: '\n      <div class="main-container">\n        <home-banner :bannerList="bannerList"></home-banner>\n        <div v-for="item in adsList" class="in-ads-container">\n            <div class="in-ads-product-slider" v-if="item.type === \'product\'">\n                <ads-product-slider :productInfo="item" page="home"></ads-product-slider>\n            </div>\n            <div class="in-ads-base" v-if="item.type === \'ads\' && item.ads_type === \'base\' ">\n                <ads-base-content :productInfo="item"></ads-base-content>\n            </div>\n            <div class="in-ads-timer" v-if="item.type === \'ads\' && item.ads_type === \'timer\' ">\n                <timer :productInfo="item"></timer>\n            </div>\n        </div>\n\n        <div class="in-g-loading">\n          <div class="in-loadmore" v-show="isLoadingShow"></div>\n          <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="scrollDistance" ></div>\n        </div>\n\n        <home-reviews></home-reviews>\n      </div>\n    ',
    data: function data() {
      return {
        bannerList: [],
        adsList: [],
        busy: false,
        page: 2,
        scrollDistance: 900,
        isLoadingShow: false,
        versionList: window.cmsHomeDataJSON.home_version || [],
        currentVersion: 'default'
      };
    },

    components: {
      homeBanner: homeBanner,
      adsProductSlider: adsProductSlider,
      adsBaseContent: adsBaseContent,
      timer: timer,
      homeReviews: homeReviews
    },
    created: function created() {
      this.currentVersion = Util.getAbVersion(this.versionList);
      this.init();
    },

    methods: {
      init: function init() {
        var cmsHomeData = window.cmsHomeDataJSON;
        if (cmsHomeData.ret_code === 200) {
          this.bannerList = cmsHomeData.data[this.currentVersion].home_banner;
          this.adsList = cmsHomeData.data[this.currentVersion].home_ads;
        }
      },
      getHomeAds: function getHomeAds() {
        var _this = this;

        homeAdsApi({
          page: this.page
        }).then(function (resData) {
          if (resData.length < 3) {
            _this.busy = true;
            _this.isLoadingShow = false;
          } else {
            _this.busy = false;
          }
          _this.page++;
          _this.adsList = [].concat(_toConsumableArray(_this.adsList), _toConsumableArray(resData));
        });
      },
      loadMore: function loadMore() {
        var _this2 = this;

        this.isLoadingShow = true;
        this.busy = true;

        setTimeout(function () {
          _this2.getHomeAds();
        }, 500);
      }
    }
  };
});
