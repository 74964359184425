'use strict';

/* eslint no-undef: "off" */
/**
 * base api
 */
define('baseApi',['axios', 'appStorage', 'busEvent', 'util'], function (axios, appStorage, busEvent, Util) {

    // axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'

    return {
        create: function create(options) {
            //recreate axios
            this.created = true;
            this.axios = axios.create(options);
        },
        destroy: function destroy() {
            this.created = false;
            this.axios = undefined;
        },

        beforeRequestSent: function beforeRequestSent(options) {
            if (!this.created) {
                this.axios = axios.create({
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Ab-Version': Util.getCookie('ab_version') === "" ? 'default' : Util.getCookie('ab_version')
                    },
                    timeout: 30000
                });
            }

            if (!options.noLoading) {
                busEvent.$emit('beginLoading');
            }
            if (appStorage.token()) {
                this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + appStorage.token();
            }
            if (typeof ga !== 'undefined' && ga.getAll && ga.getAll()[0]) {

                this.axios.defaults.headers.common['X-TrackerId'] = ga.getAll()[0].get('clientId');
            }
            if (options && options.removeCache) {
                if (typeof options.removeCache === 'string') {
                    appStorage.remove(options.removeCache);
                } else {
                    options.removeCache.forEach(function (e) {
                        appStorage.remove(e);
                    });
                }
            }
            if (options && options.cache && appStorage.get(options.cache)) {
                return new Promise(function (resolve, reject) {
                    resolve(appStorage.get(options.cache));
                });
            }
        },
        getExtraParams: function getExtraParams() {
            return {
                'version': appStorage.getConfig().device
            };
        },
        get: function get(url, params, options) {
            var _this = this;

            options = options || {};
            var promise = this.beforeRequestSent(options || {});
            if (promise) {
                if (!options.noLoading) {
                    busEvent.$emit('endLoading');
                }
                return promise;
            }
            var defaultParams = this.getExtraParams();

            if (options.removeHeaders && Array.isArray(options.removeHeaders)) {
                options.removeHeaders.forEach(function (item) {
                    delete _this.axios.defaults.headers[item];
                    delete _this.axios.defaults.headers.common[item];
                });
            }
            promise = this.axios.get(url, { params: Object.assign(defaultParams, params) });
            return this.beforeResponseDispatched(promise, options || {});
        },
        post: function post(url, params, options) {
            options = options || {};
            var promise = this.beforeRequestSent(options);
            if (promise) {
                if (!options.noLoading) {
                    busEvent.$emit('endLoading');
                }
                return promise;
            }
            var defaultParams = this.getExtraParams();
            var queryParams = [];
            var exps = this.getExtraParams();
            for (p in exps) {
                queryParams.push(p + '=' + exps[p]);
            }
            var paramStr = queryParams.join('&');
            if (paramStr) {
                if (url.indexOf('?') === -1) {
                    url = url + '?' + paramStr;
                } else {
                    url = url + '&' + paramStr;
                }
            }
            promise = this.axios.post(url, params);
            return this.beforeResponseDispatched(promise, options || {});
        },

        beforeResponseDispatched: function beforeResponseDispatched(promise, options) {
            return promise.then(function (resp) {
                if (!options.noLoading) {
                    busEvent.$emit('endLoading');
                }
                if (resp.status === 200 || resp.status === 401) {
                    if (resp.data.code == 200 || resp.data.status == 1) {
                        if (options.cache) {
                            appStorage.set(options.cache, resp.data.data);
                        }
                        if (options.setCache) {
                            appStorage.set(options.setCache, resp.data.data);
                        }
                        if (options.callback) {
                            options.callback(resp.data.data);
                        }
                        return resp.data.data;
                    }
                    e = resp.data;
                    if (e.code == 101) {
                        appStorage.clear();
                        busEvent.$emit('showLoginModal');
                    } else if (e.code == 100 || e.code == 10000) {
                        busEvent.$emit('modalError', e.message);
                    }
                }
                return new Promise(function (resolve, reject) {
                    reject(resp.data);
                });
            }, function (e) {
                if (!options.noLoading) {
                    busEvent.$emit('endLoading');
                }
                busEvent.$emit('modalError', e.message);
            });
        }
    };
});
