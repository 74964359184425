'use strict';

/* eslint no-undef: "off" */
/**
 * cms index app
 */
define('homeApp',['headerWrapper', 'footerWrapper', 'cmsIndexMain', 'homeNewsletter', 'service', 'searchPanel', 'GAReport'], function (headerWrapper, footerWrapper, cmsIndexMain, homeNewsletter, service, searchPanel, GAReport) {

  new GAReport().init({
    pageGroup: "HOME"
  });

  return {
    template: '\n      <div class="page-container cms-index">\n          <header-wrapper></header-wrapper>\n          <cms-index-main></cms-index-main>\n          <!--\n          <service></service>\n          -->\n          <home-newsletter></home-newsletter>\n          <footer-wrapper></footer-wrapper>\n          <!--\n          <search-panel></search-panel>\n          -->\n      </div>\n    ',
    components: {
      headerWrapper: headerWrapper,
      footerWrapper: footerWrapper,
      cmsIndexMain: cmsIndexMain,
      service: service,
      searchPanel: searchPanel,
      homeNewsletter: homeNewsletter

    }
  };
});
