'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

define('vue',['vueBase', 'busEvent', 'commonBehaviours', 'modal', 'vueTopProgress', 'validate', 'appStorage'], function (VueBase, busEvent, commonBehaviours, VModal, vueTopProgress, validate, appStorage) {

    VueBase.config.productionTip = false;
    VueBase.use(VModal.default, { componentName: 'modal' });
    VueBase.use(vueTopProgress);
    VueBase.use(validate, {
        locale: 'en',
        events: ''
    });

    var i18nMap = appStorage.getI18nMap();

    function replaceTranslations(options) {
        if (options.data && !options.oldDataFunc) {
            options.oldDataFunc = options.data;
            options.data = function () {
                data = this.oldDataFunc();
                if (data.i18n) {
                    for (var k in data.i18n) {
                        if (i18nMap[data.i18n[k]]) {
                            data.i18n[k] = i18nMap[data.i18n[k]];
                        }
                    }
                }
                return data;
            }.bind(options);
        }
        if (options.components) {
            for (var i in options.components) {
                replaceTranslations(options.components[i]);
            }
        }
    }

    var Vue = function (_VueBase) {
        _inherits(Vue, _VueBase);

        function Vue(options) {
            _classCallCheck(this, Vue);

            if (options && options.el == '#app' && options.template) {
                options.template = '<div>' + options.template + '<common-behaviours /></div>';
                options.components.commonBehaviours = commonBehaviours;
            }
            replaceTranslations(options);

            var _this = _possibleConstructorReturn(this, (Vue.__proto__ || Object.getPrototypeOf(Vue)).call(this, options));

            _this.initEvents();
            return _this;
        }

        _createClass(Vue, [{
            key: 'initEvents',
            value: function initEvents() {
                busEvent.$on('modalConfirmed', this.confirmActionHandlerApplier.bind(this));
            }
        }, {
            key: 'confirmActionHandlerApplier',
            value: function confirmActionHandlerApplier() {
                if (this.confirmActionHandler) {
                    this.confirmActionHandler.apply();
                }
            }
        }, {
            key: 'success',
            value: function success(message, options) {
                busEvent.$emit('produceHideEvent', options);
                busEvent.$emit('modalSuccess', message);
            }
        }, {
            key: 'hint',
            value: function hint(message) {
                busEvent.$emit('modalHint', message);
            }
        }, {
            key: 'error',
            value: function error(message) {
                busEvent.$emit('modalError', message);
            }
        }, {
            key: 'confirm',
            value: function confirm(message, cb) {
                busEvent.$emit('confirmModal', message);
                this.confirmActionHandler = cb;
            }
        }, {
            key: 'loading',
            value: function loading() {
                busEvent.$emit('beginLoading');
            }
        }, {
            key: 'loaded',
            value: function loaded() {
                busEvent.$emit('endLoading');
            }
        }]);

        return Vue;
    }(VueBase);

    return Vue;
});
