'use strict';

/* eslint no-undef: "off" */
/**
 * ads base content
 */
define('adsBaseContent',['GAReport'], function (GAReport) {
  return {
    template: '\n      <div class="in-ads-base-content">\n        <div class="in-ads-base-title" v-if="productInfo.title_position">\n            <h2 v-text="productInfo.name"></h2>\n            <hr />\n        </div>\n        <a :href="productInfo.url" @click="onPromotionClicked(productInfo)">\n            <img :src="productInfo.banner" :alt="productInfo.name">\n        </a>\n      </div>\n    ',
    props: {
      'productInfo': {
        type: Object,
        default: {},
        required: true
      }
    },
    mounted: function mounted() {
      this.reportGAPromotionView(this.productInfo);
    },

    methods: {
      reportGAPromotionView: function reportGAPromotionView(data) {
        if (!data.id || !data.name) {
          return;
        }
        var promotions = [{
          'id': data.id || "",
          'name': data.name,
          'creative': "",
          'position': 1
        }];

        new GAReport().promotionView({
          'eventAction': 'HOME_BANNER',
          'eventCategory': 'HOME_BANNER_#VERSION#',
          'promotions': promotions
        });
      },
      onPromotionClicked: function onPromotionClicked(data) {
        if (!data.id || !data.name) {
          return;
        }
        var promotions = [{
          'id': data.id || "",
          'name': data.name,
          'creative': "",
          'position': 1
        }];
        new GAReport().promotionClick({
          'eventAction': 'HOME_BANNER_' + data.name,
          'eventCategory': 'HOME_BANNER_#VERSION#',
          'promotions': promotions
        });
      }
    }
  };
});
