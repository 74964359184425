'use strict';

/* eslint no-undef: "off" */
/**
 * global event bus
 */
define('busEvent',['vueBase'], function (VueBase) {
  VueBase.config.productionTip = false;
  return new VueBase();
});
