'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/* eslint no-undef: "off" */
/**
 * util工具文件
 */
define('util',[],function () {
  var Util = function () {
    function Util() {
      _classCallCheck(this, Util);
    }

    _createClass(Util, null, [{
      key: 'validateHttp',

      // 验证所有的requirest请求状态
      value: function validateHttp(response) {
        return new Promise(function (resolve, reject) {
          if (response.data.code === 200 && response.data.message === '') {
            resolve(response.data.data);
          } else {
            reject(response.data);
          }
        });
      }

      /**
       * 重定向到指定的url
       * @param url
       */

    }, {
      key: 'redirect',
      value: function redirect(url) {
        window.location.href = url;
      }

      /**
       * 从字符串中提取价格
       * @param {string} priceLabel
       */

    }, {
      key: 'formatPrice',
      value: function formatPrice(priceLabel) {
        var reg = new RegExp(/(\d+,)?\d+[\\.]\d+/ig);
        var arr = reg.exec(priceLabel);
        if (arr !== null) {
          return arr[0];
        }
      }

      /**
       * 将有千分号隔符，处理为number
       * @param {number} price
       */

    }, {
      key: 'formatNumberPrice',
      value: function formatNumberPrice(price) {
        if (typeof price === 'Number') {
          return price;
        }
        price = price.replace(',', '');
        var exp = /([\d\.]+)/.exec(price);
        if (exp.length) {
          return Number(exp[0]);
        }
        return 0;
      }

      /**
       * 防抖函数
       * @param {*} handler
       * @param {*} wait
       * @param {*} content
       */

    }, {
      key: 'debounce',
      value: function debounce(handler, wait) {
        var timeId = null;
        return function () {
          var ctx = this;
          var args = arguments;
          if (timeId) clearTimeout(timeId);
          timeId = setTimeout(function () {
            handler.apply(ctx, args);
          }, wait);
        };
      }

      /**
       * 格式化cart item 数据
       * @param {*} cartItem
       */

    }, {
      key: 'normalizeCartItem',
      value: function normalizeCartItem(cartItem) {
        var map = {};
        cartItem.forEach(function (item) {
          if (!map[item.warehouse]) {
            map[item.warehouse] = [];
          }
          map[item.warehouse].push(item);
        });
        return map;
      }
    }, {
      key: 'isInViewPortOfTwo',
      value: function isInViewPortOfTwo(el) {
        if (!el) return false;
        var viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        var top = el.getBoundingClientRect() && el.getBoundingClientRect().top;
        return top <= viewPortHeight + 100;
      }
    }, {
      key: 'getURLSearchParams',
      value: function getURLSearchParams() {
        var search = window.location.search;
        if (search) {
          return new URLSearchParams(search.slice(1));
        }

        return new URLSearchParams();
      }
    }, {
      key: 'Logout',
      value: function Logout() {
        window.localStorage && window.localStorage.removeItem('token');
        Util.redirect('/customer/account/logout/');
      }

      /**
       * 获取Token
       * @returns String
       */

    }, {
      key: 'getToken',
      value: function getToken() {
        return window.localStorage && window.localStorage.getItem('token') || '';
      }
    }, {
      key: 'isXparkBrowser',
      value: function isXparkBrowser() {
        return new RegExp('XPark-App').test(navigator.userAgent);
      }
    }, {
      key: 'isH5App',
      value: function isH5App() {
        return new RegExp('h5xparkwb').test(navigator.userAgent);
      }
    }, {
      key: 'getAppUrl',
      value: function getAppUrl(url, target, id) {
        if (this.isXparkBrowser() && url.indexOf('target=') === -1) {
          var symbol = '?';
          if (url.indexOf('?') !== -1) {
            symbol = '&';
          }
          return url + symbol + 'target=' + target + '&id=' + id;
        }
        return url;
      }
    }, {
      key: 'subtractPrice',
      value: function subtractPrice(price, discount) {
        var op = price.replace(',', '');
        var fprice = this.formatNumberPrice(price) - this.formatNumberPrice(discount);
        fprice = fprice.toLocaleString();
        var exp = /([\d\.\,]+)/.exec(price);

        if (exp.length) {
          return price.replace(exp[1], fprice);
        }
        return price;
      }
    }, {
      key: 'getCookie',
      value: function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i].trim();
          if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return "";
      }
    }, {
      key: 'setCookie',
      value: function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + "; " + expires;
      }
    }, {
      key: 'compareValues',
      value: function compareValues(a, b) {
        var comparion = 0;
        if (Number(a.ab_version_percent) > Number(b.ab_version_percent)) {
          comparion = 1;
        } else if (Number(a.ab_version_percent) < Number(b.ab_version_percent)) {
          comparion = -1;
        }
        return comparion;
      }
    }, {
      key: 'getAbVersion',
      value: function getAbVersion(data) {
        if (Util.getCookie('ab_version') !== "") return Util.getCookie('ab_version');
        var sortData = data.sort(Util.compareValues);
        var versionRand = Math.random() * 100;
        var currentRand = 0;
        var currentVersion = 'default';

        for (var i = 0; i < sortData.length; i++) {
          if (versionRand <= Number(sortData[i].ab_version_percent) + currentRand) {
            currentVersion = sortData[i].ab_version_code;
            break;
          } else {
            currentRand += Number(sortData[i].ab_version_percent);
          }
        }
        Util.setCookie("ab_version", currentVersion, 30);
        return currentVersion;
      }
    }]);

    return Util;
  }();

  return Util;
});
