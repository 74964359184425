'use strict';

/* eslint no-undef: "off" */
/**
 * search modal
 */
define('countryChoose',['busEvent', 'jquery', 'util', 'GAReport'], function (busEvent, $, Util, GAReport) {
  return {
    template: '\n        <div v-show="isModalShow" class="xp-country-container">\n          <div class="xp-country-content">\n            <i @click="onCloseBtnClicked" class="xp-iconfont xp-icon-close xp-country-close"></i>\n            <img class="xp-country-logo" :src="logo.url">\n            <p class="xp-country-tips" v-text="i18n.countryTips"></p>\n            <ul class="xp-country-list">\n              <li v-for="(item, index) in countries" :key="index" class="xp-country-item">\n                <a class="xp-country-link" :class="{active: item.isCurrent}" :href="item.href" @click="onCountryBtnClicked(item.title)">\n                  <img class="xp-country-image" :src="item.image">\n                  <span v-text="item.title"></span>\n                </a>\n              </li>\n            </ul>\n          </div>\n        </div>\n      ',
    data: function data() {
      return {
        isModalShow: false,
        logo: window.commonDataJSON.logo,
        i18n: {
          countryTips: "please select your country or region"
        },
        countries: window.commonDataJSON.xpark_webs || []
      };
    },
    mounted: function mounted() {
      this.onShowEvent();
      console.log('commonData', window.commonDataJSON);
      console.log('countries', this.countries);
    },

    methods: {
      onShowEvent: function onShowEvent() {
        var _this = this;

        busEvent.$on('showCountryModal', function () {
          $('body').css("overflow", "hidden");
          _this.isModalShow = true;
        });
      },
      onCloseBtnClicked: function onCloseBtnClicked() {
        $('body').css("overflow", "auto");
        this.isModalShow = false;
      },
      onCountryBtnClicked: function onCountryBtnClicked(country) {
        new GAReport().customerClick({
          eventAction: 'COUNTRY_CHANGE_TO_' + country,
          eventCategory: 'COUNTRY_CHANGE_#VERSION#'
        });
      }
    }
  };
});
