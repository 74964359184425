'use strict';

/* eslint no-undef: "off" */
/**
 * header menu
 */
define('headerMenu',['busEvent', 'GAReport'], function (busEvent, GAReport) {
  return {
    template: '\n      <div class="in-header-menu">\n        <div class="in-container">\n          <div class="in-menu-wrapper">\n            <ul>\n              <li class="in-menu" v-for="item in menus">\n                <a :href="item.link" v-text="item.name" @click="onHeaderMenuClicked(item.name)"></a>\n                <ul\n                  v-if="item.children && item.children.length"\n                  class="in-submenu"\n                >\n                  <li v-for="children in item.children">\n                    <a :href="children.link" v-text="children.name"  @click="onHeaderMenuClicked(item.name + \'/\' + children.name)"></a>\n                  </li>\n                </ul>\n              </li>\n            </ul>\n          </div>\n        </div>\n      </div>\n    ',
    data: function data() {
      return {
        menus: window.commonDataJSON.menus,
        logo: window.commonDataJSON.logo,
        cartQty: window.commonDataJSON.cart_qty
      };
    },
    mounted: function mounted() {
      this.cartQtyEvent();
    },

    methods: {
      cartQtyEvent: function cartQtyEvent() {
        var _this = this;

        busEvent.$on('cartQtyEvent', function (qty) {
          _this.cartQty = qty;
        });
      },
      onHeaderMenuClicked: function onHeaderMenuClicked(text) {
        new GAReport().customerClick({
          eventAction: 'TOP_MENU_' + text,
          eventCategory: 'TOP_MENUS_#VERSION#'
        });
      }
    }
  };
});
