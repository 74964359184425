'use strict';

/* eslint no-undef: "off" */
/**
 * bind tips
 */
define('bindTips',['deleteBindConfirmSessionApi', 'util'], function (deleteBindConfirmSessionApi, Util) {
  return {
    template: '\n      <div class="bind-tips">\n        <modal\n          v-if="status"\n          name="tips"\n          height="auto"\n          :transition="\'overlay-fade\'"\n          @closed="closedModal"\n        >\n          <div class="in-modal">\n            <i class="iconfont" :class="{\n              \'icon-success\': iconSuccess == \'success\',\n              \'icon-tishi1\': iconSuccess == \'hint\',\n              \'icon-error\': iconSuccess == \'error\',\n            }"></i>\n            <p v-text="msg"></p>\n            <div class="in-btn-group-new">\n              <div class="in-btn in-continue-btn">\n                  <a href="#" v-text="i18n.cancel" @click.prevent="cancelBind"></a>\n              </div>\n              <div class="in-btn" >\n                  <a :href="url" v-text="i18n.binding"></a>\n              </div>\n            </div>\n          </div>\n        </modal>\n\n      </div>\n    ',
    data: function data() {
      return {
        status: false,
        msg: '',
        url: '',
        i18n: {
          cancel: 'Cancel',
          binding: 'Binding'
        },
        iconSuccess: 'hint' // success/error/hint
      };
    },
    mounted: function mounted() {
      this.initBind();
    },

    methods: {
      initBind: function initBind() {
        var data = window.commonDataJSON;
        if (typeof data.fan_bind_confirm === 'boolean') {
          this.status = !data.fan_bind_confirm;
          this.msg = data.fan_bind_msg;
          this.url = data.fan_bind_url;
          return this.status && this.showModal();
        }
        if (typeof data.net_red_bind_confirm === 'boolean') {
          this.status = !data.net_red_bind_confirm;
          this.msg = data.net_red_bind_msg;
          this.url = data.net_red_bind_url;
          return this.status && this.showModal();
        }
      },

      /**
       * 显示modal
       */
      showModal: function showModal() {
        var _this = this;

        setTimeout(function () {
          _this.$modal.show('tips');
        }, 20);
      },

      /**
       * 隐藏modal
       */
      hideModal: function hideModal() {
        this.$modal.hide('tips');
      },

      /**
       * 关闭modal
       */
      closedModal: function closedModal() {
        this.cancelBind();
      },
      cancelBind: function cancelBind() {
        var _this2 = this;

        deleteBindConfirmSessionApi().then(function (resData) {
          _this2.hideModal();
        });
      }
    }
  };
});
