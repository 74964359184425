'use strict';

/* eslint no-undef: "off" */
/**
 * base api get method
 */
define('baseApiGet',['baseApi'], function (baseApi) {
  return function (url) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};


    return baseApi.get(url, params, options);
  };
});
