'use strict';

/* eslint no-undef: "off" */
/**
 * header wrapper
 */
define('headerWrapper',['headerQuick', 'headerMenu', 'GAReport'], function (headerQuick, headerMenu, GAReport) {
  return {
    template: '\n      <header class="header-container">\n        <header-quick></header-quick>\n        <header-menu></header-menu>\n      </header>\n    ',
    components: {
      headerQuick: headerQuick,
      headerMenu: headerMenu
    }
  };
});
