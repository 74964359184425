'use strict';

/* eslint no-undef: "off" */
/**
 * get home customer reviews
 */
define('getHomeReviewsApi',['baseApiGet'], function (baseApiGet) {
  return function () {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return baseApiGet('/rest/V1/xmapi/product/unboxing', params);
  };
});
