'use strict';

/* eslint no-undef: "off" */
/**
 * change City api
 */
define('changeCityApi',['baseApiGet'], function (baseApiGet) {
  return function () {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return baseApiGet('/rest/V1/xmapi/changeCity', params, { setCache: 'city_list', removeCache: ['cart', 'buynow'] });
  };
});
