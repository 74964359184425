'use strict';

/* eslint no-undef: "off" */
/**
 * footer service
 */
define('service',[],function () {
  return {
    template: '\n      <div class="in-service-list">\n        <div class="in-container">\n          <ul>\n            <li class="in-service-item" v-for="item in serviceList">\n                <i class="icon-services" :class="item.icon"></i>\n                <a :href="item.href" target=\'_blank\' v-text="item.service"></a>\n            </li>\n          </ul>\n        </div>\n        </div>\n    ',
    data: function data() {
      return {
        i18n: {
          genuineGuaranteed: 'Genuine Guaranteed',
          superLogistics: 'Super Logistics',
          premiumServices: 'Premium Services',
          serviceList: []
        }
      };
    },
    created: function created() {
      this.serviceList = [{
        service: this.i18n.genuineGuaranteed,
        icon: 'xp-iconfont xp-icon-genuineguaranteed',
        href: '/genuine-guaranteed'
      }, {
        service: this.i18n.superLogistics,
        icon: 'xp-iconfont xp-icon-superlogistics',
        href: '/delivery-information'
      }, {
        service: this.i18n.premiumServices,
        icon: 'xp-iconfont xp-icon-premiumservices',
        href: '/return-policy'
      }];
    }
  };
});
