'use strict';

/* eslint no-undef: "off" */
/**
 * base slider
 */
define('baseSlider',['swiper'], function (Swiper) {
  return {
    template: '\n      <div class="in-swiper swiper-container" ref="swiperContainer">\n        <div class="swiper-wrapper">\n            <slot></slot>\n        </div>\n\n        <div @click.prevent="preventClick" class="swiper-pagination" v-if="sliderConfig.isPagination"></div>\n        <div @click.prevent="preventClick" class="swiper-button-prev swiper-button-white" v-if="sliderConfig.isButtonPrev"></div>\n        <div @click.prevent="preventClick" class="swiper-button-next swiper-button-white" v-if="sliderConfig.isButtonNext"></div>\n        <div @click.prevent="preventClick" class="swiper-scrollbar" v-if="sliderConfig.isScrollbar"></div>\n      </div>\n    ',
    props: {
      'sliderConfig': {
        type: Object,
        default: {
          isPagination: false,
          isButtonPrev: false,
          isButtonNext: false,
          isScrollbar: false,
          coreSliderConfig: {}
        },
        required: true
      }
    },
    mounted: function mounted() {
      var _this = this;

      setTimeout(function () {
        _this.initSlider();
      }, 20);
    },

    methods: {
      preventClick: function preventClick(e) {
        e.stopPropagation();
        e.preventDefault();
        console.log('click stop');
        return true;
      },
      initSlider: function initSlider() {
        /* eslint-disable no-new */
        // new Swiper(this.$refs.swiperContainer, this.sliderConfig.coreSliderConfig)
        this.$slider = new Swiper(this.$refs.swiperContainer, this.sliderConfig.coreSliderConfig);
      }
    }
  };
});
