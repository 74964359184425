'use strict';

define('cartHelper',[], function () {
    return {
        isBuynowPage: function isBuynowPage() {
            return window.location.pathname.indexOf('/buynow') === 0 || window.location.pathname.indexOf('/pickup') === 0;
        },
        getCacheOptions: function getCacheOptions() {
            if (this.isBuynowPage()) {
                return { setCache: 'buynow' };
            } else {
                return { setCache: 'cart' };
            }
        },
        getCacheName: function getCacheName() {
            return this.isBuynowPage() ? 'buynow' : 'cart';
        }
    };
});
