'use strict';

/* eslint no-undef: "off" */
/**
 * pp shop content
 */
define('commonBehaviours',['busEvent', 'appStorage'], function (busEvent, appStorage) {
  return {
    template: '\n      <div>\n        <modal\n          name="information"\n          height="auto"\n          :transition="\'overlay-fade\'"\n          @closed="hideModal"\n        >\n          <div class="in-modal">\n            <i class="iconfont icon-guanbi" @click.prevent="hideModal"></i>\n            <img v-if="iconSuccess == \'success\'" :src="require.toUrl(\'images/success_hook.png\')"/>\n            <i class="iconfont" :class="{\n              \'icon-tishi1\': iconSuccess == \'hint\',\n              \'icon-error\': iconSuccess == \'error\',\n            }"></i>\n            <p v-text="modalMsg"></p>\n            <div v-if="linkBar" class="in-btn-group-new center-group">\n              <template>\n                <div class="in-btn" v-for="link in links"><a :href="link.href" v-text="link.title"></a></div>\n              </template>\n            </div>\n            <div v-if="confirmBar" class="modal-footer">\n              <div :style="{\'max-width\': \'100%\'}" class="btn-center">\n                <div class="in-btn">\n                  <button\n                    v-text="i18n.confirm"\n                    @click.prevent="confirmAction"\n                  ></button>\n                </div>\n                <div class="in-btn">\n                  <button\n                    @click.prevent="hideModal"\n                    v-text="i18n.cancel"\n                  ></button>\n                </div>\n              </div>\n            </div>\n          </div>\n        </modal>\n\n        <vue-topprogress\n          ref="topProgress"\n          :color="\'#009944\'"\n          :height="4">\n        </vue-topprogress>\n      </div>\n    ',
    data: function data() {
      return {
        i18n: {
          cancel: 'Cancel',
          confirm: 'Confirm',
          defaultSuccess: 'Operation Successful!',
          defaultHint: 'Something went wrong, please try again later!',
          defaultError: 'Operation Failed!',
          defaultLogin: "You've not login yet, please login!",
          login: 'Login'
        },
        modalMsg: '',
        iconSuccess: '', // success/error/hint
        confirmBar: false,
        linkBar: false,
        links: [],
        hideEventQueue: [],
        loadingNested: 0,
        token: ''
      };
    },
    created: function created() {
      busEvent.$on('modalSuccess', this.showSuccessModal.bind(this));
      busEvent.$on('modalHint', this.showHintModal.bind(this));
      busEvent.$on('modalError', this.showErrorModal.bind(this));
      busEvent.$on('confirmModal', this.confirmModal.bind(this));
      busEvent.$on('beginLoading', this.beginLoading.bind(this));
      busEvent.$on('endLoading', this.endLoading.bind(this));
      busEvent.$on('showLoginModal', this.showLoginModal.bind(this));
      busEvent.$on('showLinkMessage', this.showLinkMessage.bind(this));
      busEvent.$on('produceHideEvent', this.hideEventProducer.bind(this));
      busEvent.$on('getCookie', this.getCookie.bind(this));
      busEvent.$on('setToken', this.setToken.bind(this));
    },
    mounted: function mounted() {},

    methods: {
      beginLoading: function beginLoading() {
        if (this.loadingNested === 0) {
          this.$refs.topProgress.start();
        }
        this.loadingNested++;
      },
      hideEventProducer: function hideEventProducer(ev) {
        this.hideEventQueue.push(ev);
      },
      consumHideEvent: function consumHideEvent() {
        while (ev = this.hideEventQueue.pop()) {
          switch (ev.action) {
            case 'reload':
              this.beginLoading();
              location.reload();
              break;
            default:
              break;
          }
        }
      },
      endLoading: function endLoading() {
        this.loadingNested--;
        if (this.loadingNested <= 0) {
          var that = this;
          setTimeout(function () {
            that.$refs.topProgress.done();
            that.loadingNested = 0;
          }, 50);
        }
      },
      showSuccessModal: function showSuccessModal(message) {
        this.iconSuccess = 'success';
        this.showModal(message || this.i18n.defaultSuccess);
      },
      showErrorModal: function showErrorModal(message) {
        this.iconSuccess = 'error';
        this.showModal(message || this.i18n.defaultError);
      },
      showHintModal: function showHintModal(message) {
        this.iconSuccess = 'hint';
        this.showModal(message || this.i18n.defaultHint);
      },
      confirmModal: function confirmModal(message) {
        this.iconSuccess = 'hint';
        this.confirmBar = true;
        this.linkBar = false;
        this.modalMsg = message;
        this.$modal.show('information');
      },
      showModal: function showModal(message) {
        this.modalMsg = message;
        this.confirmBar = false;
        this.linkBar = false;
        this.$modal.show('information');
      },
      showLinkMessage: function showLinkMessage(message, links) {
        this.iconSuccess = 'hint';
        this.confirmBar = false;
        this.linkBar = true;
        this.modalMsg = message;
        this.links = links;
        this.$modal.show('information');
      },
      showLoginModal: function showLoginModal() {
        this.showLinkMessage(this.i18n.defaultLogin, [{ href: '/customer/account/login', title: this.i18n.login }]);
      },
      confirmAction: function confirmAction() {
        this.hideModal();
        busEvent.$emit('modalConfirmed');
      },

      /**
       * 隐藏modal
       */
      hideModal: function hideModal() {
        this.$modal.hide('information');
        this.consumHideEvent();
      },

      /**
       * 关闭modal
       */
      closedModal: function closedModal() {
        this.hideModal();
      },
      getCookie: function getCookie(cookieName) {
        var arrCookie = document.cookie.split(";");
        for (var i = 0; i < arrCookie.length; i++) {
          var arr = item.split("=");
          if (cookieName === arr[0]) {
            return arr[1];
          }
        }
        return "";
      },
      setToken: function setToken() {
        this.token = this.getCookie('X-Set-Token');
        if (token) {
          appStorage.set('token', token);
          appStorage.set('customer', { token: token });
        }
      }
    }
  };
});
