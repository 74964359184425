'use strict';

/* eslint no-undef: "off" */
/**
 * home banner
 */
define('homeBanner',['baseSlider', 'util', 'GAReport'], function (baseSlider, Util, GAReport) {
  return {
    template: '\n      <div class="in-home-banner">\n        <div class="in-banner-container">\n          <base-slider :sliderConfig="sliderConfig" v-if="curtBannerList.length > 0 && deviceType === \'pc\'" ref="banner" key="pc">\n            <div class="swiper-slide" v-for="(item, index) in curtBannerList" :key="index" >\n                <div>\n                  <a @click.prevent="(e) => preventClick(e, item)" class="swiper-lazy" :data-background="item.img"></a>\n                  <div class="swiper-lazy-preloader"></div>\n                </div>\n            </div>\n          </base-slider>\n\n          <base-slider :sliderConfig="sliderConfig" v-if="curtBannerList.length > 0 && deviceType === \'pc_small\'" ref="banner" key="pc_small">\n            <div class="swiper-slide" v-for="(item, index) in curtBannerList" :key="index" >\n                <div>\n                  <a @click.prevent="(e) => preventClick(e, item)" class="swiper-lazy" :data-background="item.img"></a>\n                  <div class="swiper-lazy-preloader"></div>\n                </div>\n            </div>\n          </base-slider>\n        </div>\n      </div>\n    ',
    data: function data() {
      return {
        sliderConfig: {
          isPagination: true,
          isButtonPrev: true,
          isButtonNext: true,
          coreSliderConfig: {
            lazy: true,
            loop: true,
            observer: true,
            autoplay: {
              delay: 6000,
              disableOnInteraction: false
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },
            on: {
              click: function click(e) {
                if (e.target.nodeName !== 'A') {
                  return;
                }
                var i = this ? this.activeIndex : 1;
                var data = window.curtBannerList[i - 1] || window.curtBannerList[0] || null;
                if (data) {
                  var promotions = [{
                    'id': data.id || "",
                    'name': data.alt || data.creative,
                    'creative': data.creative || "",
                    'position': i,
                    'url': data.url
                  }];
                  new GAReport().promotionClick({
                    'eventAction': 'HOME_BANNER_' + data.alt,
                    'eventCategory': 'HOME_BANNER_#VERSION#',
                    'promotions': promotions
                  });
                  location.href = data.url;
                }
              }
            }
          }
        },
        curtBannerList: []
      };
    },

    props: {
      bannerList: {
        type: Object | Array,
        required: true
      }
    },
    components: {
      baseSlider: baseSlider
    },
    created: function created() {
      this.deviceType = 'pc'; // pc pc_small
    },
    mounted: function mounted() {
      this._initData();
      this._setDeviceType(window.innerWidth);
      this._initEvent();

      this.reportGAPromotionView(this.curtBannerList);
    },

    methods: {
      _initData: function _initData() {
        this.curtBannerList = !!this.bannerList.pc_wide ? this.bannerList.pc_wide : [];
        window.curtBannerList = this.curtBannerList;
      },
      _setDeviceType: function _setDeviceType(width) {
        if (width < 1400) {
          this.deviceType = 'pc_small';
          console.log(this.bannerList.pc_small);
          if (this.bannerList.pc_small && this.bannerList.pc_small.length > 0) {
            this.curtBannerList = !!this.bannerList.pc_small ? this.bannerList.pc_small : [];
            window.curtBannerList = this.curtBannerList;
            return;
          }
        }
        this.deviceType = 'pc';
        this.curtBannerList = !!this.bannerList.pc_wide ? this.bannerList.pc_wide : [];
        window.curtBannerList = this.curtBannerList;
      },
      _initEvent: function _initEvent() {
        var self = this;
        window.addEventListener('resize', Util.debounce(function () {
          var width = window.innerWidth;
          self._setDeviceType(width);
        }, 200));
      },
      preventClick: function preventClick(e, item) {
        e.preventDefault();
      },
      reportGAPromotionView: function reportGAPromotionView(list) {
        var promotions = [];
        (list || []).map(function (item, index) {
          promotions.push({
            'id': item.id || "",
            'name': item.alt || item.creative,
            'creative': item.creative || "",
            'position': index + 1,
            'url': item.url
          });
        });
        new GAReport().promotionView({
          'eventAction': 'HOME_BANNER',
          'eventCategory': 'HOME_BANNER_#VERSION#',
          'promotions': promotions
        });
      }
    }
  };
});
