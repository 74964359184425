'use strict';

/* eslint no-undef: "off" */
/**
 * get City List api
 */
define('getCityListApi',['baseApiGet'], function (baseApiGet) {
  return function () {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return baseApiGet('/rest/V1/xmapi/cityList', params, {
      cache: 'city_list'
    });
  };
});
