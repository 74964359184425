define('appStorage',[], function() {
    
    var storage = window.localStorage;
    var events = {};
    const appConfig = {
    "device": "pc",
    "locale": "en_US"
};
    appConfig.deploy_version = new RegExp(/version(\d+)/).exec(require.toUrl(''))[1];
    const config = JSON.parse(storage.getItem('app_config'));
    if (!config || config.deploy_version !== appConfig.deploy_version) {
        storage.setItem('app_config', JSON.stringify(appConfig));
    }

    const i18nMap = {"ACCOUNT":"ACCOUNT","PINBOARD":"PINBOARD"};

    return {
        getConfig: function() {
            return appConfig;
        },
        set: function (key, value) {
            if (events[key] && events[key].length) {
                events[key].forEach(function(cb) {
                    cb(value, JSON.parse(storage.getItem(key)));
                });
            }
            return storage.setItem(key, JSON.stringify(value));
        },
        remove: function (key) {
        
            if (events[key] && events[key].length) {
                events[key].forEach(function(cb) {
                    cb(null, JSON.parse(storage.getItem(key)));
                });
            }
            return storage.removeItem(key);
        },
        isLogin: function() {
            return !!storage.getItem('token');
        },
        login: function (token) {
            storage.setItem('token', token);
        },
        token: function() {
            return storage.getItem('token');
        },
        clear: function() {
            storage.clear();
        },
        get: function (key) {
            try {
                return JSON.parse(storage.getItem(key));
            } catch (e) {
                return storage.getItem(key);
            }
        },
        onChange: function(key, callback) {
            if (!events[key]) {
                events[key] = [];
            }
            events[key].push(callback);
        },
        getI18nMap: function() {
            return i18nMap;
        }
    }
});

