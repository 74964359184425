'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/* eslint no-undef: "off" */
/**
 * 倒计时
 */
define('countDownTimer',[],function () {
  var Timer = function () {
    function Timer(startTime, endTime) {
      _classCallCheck(this, Timer);

      this.startTime = startTime;
      this.endTime = endTime;
      // 标识是否还有剩余时间
      this.runTimer = false;
      this.d = '00';
      this.h = '00';
      this.m = '00';
      this.s = '00';
      this._init();
    }

    _createClass(Timer, [{
      key: '_init',
      value: function _init() {
        this._getCountdownValues();
      }
    }, {
      key: '_getCountdownValues',
      value: function _getCountdownValues() {
        var startTime = new Date(this.startTime);
        var endTime = new Date(this.endTime);
        var timeStamp = Math.floor((endTime.getTime() - startTime.getTime()) / 1000);

        if (timeStamp > 0) {
          this.runTimer = true;
          var d = Math.floor(timeStamp / (24 * 60 * 60));
          var h = Math.floor(timeStamp / (60 * 60) % 24);
          var m = Math.floor(timeStamp / 60 % 60);
          var s = Math.floor(timeStamp % 60);

          this.d = this._fillZero(d);
          this.h = this._fillZero(h);
          this.m = this._fillZero(m);
          this.s = this._fillZero(s);
        }
      }
    }, {
      key: '_fillZero',
      value: function _fillZero(n) {
        return n < 10 ? '0' + n : n;
      }
    }, {
      key: '_timer',
      value: function _timer() {
        var _this = this;

        if (this.s === '00' && this.m === '00' && this.h === '00' && this.d === '00') {
          this.runTimer = false;
        }

        if (this.s !== '00') {
          // 秒
          if (this.s < 11) {
            this.s = this._fillZero(this.s - 1);
          } else {
            this.s--;
          }

          if (this.s === '00') {
            // 处理等于00的情况
            // 分
            if (this.m > 0 && this.m < 11) {
              setTimeout(function () {
                _this.m = _this._fillZero(_this.m - 1);
              }, 1000);
            } else {
              if (parseInt(this.m) > 0) {
                setTimeout(function () {
                  _this.m--;
                }, 1000);
              }
            }

            // 等于00时处理
            if (this.m !== '00') {
              (function () {
                setTimeout(function () {
                  _this.s = '60';
                }, 1000);
              })();
            }

            if (this.m === '00') {
              // 时
              if (this.h > 0 && this.h < 11) {
                setTimeout(function () {
                  _this.h = _this._fillZero(_this.h - 1);
                }, 1000);
              } else {
                if (parseInt(this.h) > 0) {
                  setTimeout(function () {
                    _this.h--;
                  }, 1000);
                }
              }

              // 等于00时处理
              if (this.h !== '00') {
                (function () {
                  setTimeout(function () {
                    _this.m = '59';
                    _this.s = '59';
                  }, 1000);
                })();
              }

              if (this.h === '00') {
                // 天
                if (this.d > 0 && this.d < 11) {
                  setTimeout(function () {
                    _this.d = _this._fillZero(_this.d - 1);
                  }, 1000);
                } else {
                  if (parseInt(this.d) > 0) {
                    setTimeout(function () {
                      _this.d--;
                    }, 1000);
                  }
                }
                if (this.d !== '00') {
                  setTimeout(function () {
                    _this.h = '23';
                    _this.m = '59';
                    _this.s = '59';
                  }, 1000);
                }

                if (this.d === '00') {
                  this.runTimer = false;
                }
              }
            }
          }
        }
      }
      //   else { // 处理h m s 直接等于00的情况
      //     if (this.h === '00') {
      //       if (parseInt(this.d) > 0) {
      //         this._fillZero(this.d--)
      //       }
      //       this.m = 59
      //     }
      //     if (this.m === '00') {
      //       if (parseInt(this.h) > 0) {
      //         this._fillZero(this.h--)
      //       }
      //       this.s = 59
      //     }
      //     if (this.s === '00') {
      //       if (parseInt(this.m) > 0) {
      //         this._fillZero(this.m--)
      //       }
      //       this.s = 59
      //     }
      //   }
      // }

    }, {
      key: 'run',
      value: function run() {
        var _this2 = this;

        return new Promise(function (resolve, reject) {
          _this2._timer();
          resolve(_this2);
        });
      }
    }]);

    return Timer;
  }();

  return Timer;
});
