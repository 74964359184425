'use strict';

/* eslint no-undef: "off" */
/**
 * footer wrapper
 */
define('footerWrapper',['bindTips', 'GAReport'], function (bindTips, GAReport) {
  return {
    template: '\n      <footer>\n        <div class="in-help">\n          <div class="in-container" v-html="footer">\n          </div>\n        </div>\n        <!--\n        <div class="copyright" v-if="isPageShow">\n          <p v-text="copyRight"></p>\n        </div>\n        -->\n\n        <bind-tips></bind-tips>\n      </footer>\n    ',
    data: function data() {
      return {
        copyRight: '',
        footer: '',
        isPageShow: false
      };
    },

    components: {
      bindTips: bindTips
    },
    mounted: function mounted() {
      this.init();
    },

    methods: {
      init: function init() {
        this.copyRight = window.commonDataJSON.copyright;
        this.footer = window.commonDataJSON.footer;
        this.isPageShow = true;

        this.$nextTick(function () {
          document.querySelectorAll("footer .in-help-product a").forEach(function (selector) {
            selector.addEventListener("click", function () {
              new GAReport().customerClick({
                eventAction: 'TOP_FOOTER_CLICK_' + this.innerText,
                eventCategory: 'TOP_FOOTER_CLICK_#VERSION#'
              });
            });
          });
        });
      }
    }
  };
});
