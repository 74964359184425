'use strict';

/* eslint no-undef: "off" */
/**
 * get blog list api
 */
define('getCartApi',['baseApiGet', 'cartHelper'], function (baseApiGet, cartHelper) {
  return function () {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var force = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    var cacheOptions = cartHelper.getCacheOptions();
    var type = cartHelper.isBuynowPage() ? 'buynow' : 'cart';
    if (force) {
      return baseApiGet('/rest/V1/xmapi/getcart', Object.assign(params, { type: type }), cacheOptions);
    } else {
      return baseApiGet('/rest/V1/xmapi/getcart', Object.assign(params, { type: type }), { cache: cartHelper.isBuynowPage() ? 'buynow' : 'cart' });
    }
  };
});
